<template>
<div>
  <b-card no-body>
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->

        <!-- Search -->
        <b-col
          cols="12"
          md="8"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Nhập tên, mã, mobile, vị trí, email để tìm kiếm ..."
            />
          </div>
        </b-col>

        <b-col
          cols="12" md="4"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
          <b-button
            variant="primary"
            @click="New()" >
            Thêm nhân viên
          </b-button>
        </b-col>

      </b-row>

    </div>
    <b-table responsive="sm" :items="items" :fields="fields">
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>

      <template #cell(actions)="row">
        <b-button v-b-tooltip.hover.top="'Phân quyền!'" variant="primary" size="sm" @click="Permission(row.item, row.index, $event.target)" class="mr-1">
          <feather-icon icon="UserCheckIcon"/>
        </b-button>
        <b-button v-b-tooltip.hover.top="'Đổi mật khẩu!'" variant="primary" size="sm" @click="ChangePas(row.item, row.index, $event.target)" class="mr-1">
          <feather-icon icon="LockIcon"/>
        </b-button>
        <b-button v-b-tooltip.hover.top="'Cập nhật thông tin!'" variant="primary" size="sm" @click="Edit(row.item, row.index, $event.target)" class="mr-1">
          <feather-icon icon="EditIcon"/>
        </b-button>
        <b-button v-b-tooltip.hover.top="'Xoá người dùng!'" variant="danger" size="sm" @click="Delete(row.item, row.index, $event.target)">
          <feather-icon icon="DeleteIcon"/>
        </b-button>
      </template>

    </b-table>
    <b-row class="pl-2 pr-2 pb-1 pt-1 border-top">
      <b-col cols="12" md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Số bản ghi</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              style="min-width:60px"
              class="per-page-selector select-size-sm d-inline-block ml-50 mr-1"
            />
            <label>trong 1 trang</label>
          </b-col>
        <b-col cols="12" md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
          <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows"/>
        </b-col> 
    </b-row>
  </b-card>
  <b-modal id="modal-edit" size="lg" :title="modal_title" @ok="handleOk">
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="3">
          <validation-provider #default="{ errors }" rules="required">
            <b-form-group label="Tên đăng nhập" >
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.ID" :readonly="readonly()" placeholder="Tên đăng nhập"/>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Mã nhân viên" >
            <b-form-input v-model="currentItem.MA_NV" placeholder="Mã nhân viên"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="{ errors }" rules="required">
            <b-form-group label="Họ & tên">
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.FULL_NAME" placeholder="Họ tên đầy đủ"/>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <validation-provider #default="{ errors }" rules="regex:^([0-9]+)$">
            <b-form-group label="Số điện thoại" >
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.MOBILE" placeholder="Số điện thoại"/>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="{ errors }" rules="email">
            <b-form-group label="Email" >
              <b-form-input :state="errors.length > 0 ? false:null" v-model="currentItem.EMAIL" placeholder="Email"/>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Giới tính" >
            <v-select label="title" v-model="gender" :options="genders" :clearable="false"/>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Chức danh" >
            <v-select label="NAME" v-model="member_type" :options="member_types" :clearable="false"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox switch v-model="currentItem.STATUS">
              Trạng thái tài khoản
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
  <b-modal id="modal-permission" scrollable body-class="p-0" title="Gán nhóm quyền cho người dùng" @ok="handPermission">
    <b-table responsive="sm" :items="permisItems" :fields="perFields">
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>
      <template #cell(C)="row">
        <b-form-checkbox v-model="row.item.C"/>
      </template>
      <template #cell(R)="row">
        <b-form-checkbox v-model="row.item.R"/>
      </template>
      <template #cell(U)="row">
        <b-form-checkbox v-model="row.item.U"/>
      </template>
      <template #cell(D)="row">
        <b-form-checkbox v-model="row.item.D"/>
      </template>
    </b-table>
  </b-modal>
  <b-modal id="modal-pass" scrollable title="Đổi mật khẩu" @ok="handChangePass">
    <validation-observer ref="simpleRules">
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Mật khẩu hiện tại" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.old" placeholder="Mật khẩu hiện tại"/>
        </b-form-group>
      </validation-provider>
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Mật khẩu mới" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.new" placeholder="Mật khẩu mới"/>
        </b-form-group>
      </validation-provider>
      <validation-provider #default="{ errors }" rules="required">
        <b-form-group label="Nhập lại" >
          <b-form-input type="password" :state="errors.length > 0 ? false:null" v-model="changepass.retype" placeholder="Nhập lại"/>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BModal,BFormCheckbox,BFormGroup,VBTooltip } from 'bootstrap-vue'
import useJwt from "@/auth/jwt/useJwt";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'
import router from '@/router'
export default {
  components: {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,vSelect,
    FeatherIcon,BModal,BFormGroup,BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mounted(){
    this.fetchUser();
    useJwt.get('masterData',{params:{data:"member_types"}}).then(response =>{
      this.member_types = response.data.result;
    }).catch(error => {console.log(error); })
  },
  watch: {
    perPage: function(n,o){
      this.fetchUser()
    },
    searchQuery: function(n,o){
      this.fetchUser()
    },
    currentPage: function(n,o){
      this.fetchUser()
    }
  },
  methods: {
    fetchPermission: function() {
      this.permisItems=[];
      useJwt.get(`userGroup/${this.currentItem.ID}`,{})
        .then(response =>{
          this.permisItems = response.data.result;
          this.permisItems.forEach(function(i,v){
            i.C = (i.C==1)?true:false;
          })
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách quyền theo nhóm '+this.currentItem.ROLE_NAME,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchUser: function() {
      useJwt.get('organization', {
          params: {
            search: this.searchQuery,
            page: this.currentPage,
            pageSize: this.perPage
          },
        })
        .then(response =>{
          this.items = response.data.result;
          this.totalRows = response.data.meta.total;
        })
        .catch(error => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lỗi lấy danh sách nhóm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    Permission(item, index, button){
      this.currentItem = item;
      this.fetchPermission();
      this.$bvModal.show('modal-permission')
    },
    ChangePas(item,index,button){
      this.currentItem = item;
      this.$bvModal.show('modal-pass')
    },
    Edit(item, index, button) {
      this.currentItem = item;
      this.gender=this.genders.find(e => e.id===item.GENDER_ID)
      this.member_type=this.member_types.find(e => e.ID===item.MEMBER_TYPE_ID)
      this.currentItem.STATUS = item.STATUS_ID==1?true:false;
      this.modal_title= "Cập nhật người dùng"
      this.$bvModal.show('modal-edit')
    },
    New(){
      this.currentItem={}
      this.modal_title= "Thêm mới người dùng"
      this.$bvModal.show('modal-edit')
    },
    async Delete(item, index, button) {
      this.currentItem = item;
      var res = await this.$bvModal.msgBoxConfirm('Vui lòng xác nhận bạn muốn xoá người dùng: '+item.FULL_NAME, {title: 'Please Confirm',size: 'sm',})
      if (res){
        useJwt.delete(`organization/${this.currentItem.ID}`, {})
        .then(response =>{
          this.fetchUser();
          this.$toast({component: ToastificationContent,props: {title: 'Xoá người dùng thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi xoá người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    handPermission(bvModalEvt) {
      bvModalEvt.preventDefault()
      useJwt.put(`userGroup/${this.currentItem.ID}`, {
          p: this.permisItems.filter(i => i.C)
        })
        .then(response =>{
          this.fetchPermission();
          this.$bvModal.hide('modal-permission')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật quyền cho nhóm',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },
    async handChangePass(bvModalEvt){
      bvModalEvt.preventDefault();
      var validate = await this.$refs.simpleRules.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      var check = await useJwt.get('changepass',{params:{user:this.currentItem.ID,"password":this.changepass.old}}).then(response =>{return response.data.result;}).catch(error => {return error })
      if (check[0].c!=1){
        this.$toast({component: ToastificationContent,props: {title: 'Mật khẩu cũ không chính xác!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      if (this.changepass.new!=this.changepass.retype){
        this.$toast({component: ToastificationContent,props: {title: 'Mật khẩu mới và mật khẩu gõ lại khác nhau!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      if (this.changepass.new==this.changepass.old){
        this.$toast({component: ToastificationContent,props: {title: 'Mật khẩu mới và mật khẩu cũ giống nhau!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      useJwt.put('changepass', {
          id: this.currentItem.ID,
          password: this.changepass.new
        })
        .then(response =>{
          this.$bvModal.hide('modal-pass')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
          setTimeout(function(){
            localStorage.clear();
            router.push({name:'auth-login'});
          },3000);
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
    },
    async handleOk(bvModalEvt){
      bvModalEvt.preventDefault();
      var validate = await this.$refs.simpleRules.validate().then(success => {return success;});
      if (!validate){
        this.$toast({component: ToastificationContent,props: {title: 'Vui lòng kiểm tra dữ liệu!',icon: 'AlertTriangleIcon',variant: 'warning'}})
        return;
      }
      if (this.modal_title=="Cập nhật người dùng"){
        useJwt.put(`organization/${this.currentItem.ID}`, {
          id: this.currentItem.ID,
          maNv: this.currentItem.MA_NV,
          fullName: this.currentItem.FULL_NAME,
          mobile: this.currentItem.MOBILE,
          email: this.currentItem.EMAIL,
          gender: this.gender.e,
          position: this.member_type.ID+"",
          status: this.currentItem.STATUS?1:0
        })
        .then(response =>{
          this.fetchUser();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Cập nhật thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi cập nhật người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }else{
        useJwt.post('organization', {
          id: this.currentItem.ID,
          ma_nv: this.currentItem.MA_NV,
          full_name: this.currentItem.FULL_NAME,
          mobile: this.currentItem.MOBILE,
          email: this.currentItem.EMAIL,
          gender: this.gender.e,
          position: this.member_type.ID+"",
          status: this.currentItem.STATUS?1:0
        })
        .then(response =>{
          this.fetchUser();
          this.$bvModal.hide('modal-edit')
          this.$toast({component: ToastificationContent,props: {title: 'Thêm mới thành công!',icon: 'CheckSquareIcon',variant: 'primary'}})
        })
        .catch(error => {
          console.log(error);
          this.$toast({component: ToastificationContent,props: {title: 'Lỗi thêm mới người dùng',icon: 'AlertTriangleIcon',variant: 'danger'}})
        })
      }
    },
    readonly(){
      return !(this.modal_title=="Thêm mới người dùng");
    }
  },
  data() {
    return {
      items: [],
      permisItems: [],
      perPageOptions: [10, 25, 50, 100],
      perPage:10,
      currentPage:1,
      totalRows:0,
      searchQuery:"",
      gender:{},
      member_type:{},
      currentItem:{},
      changepass:{},
      modal_title: "Thêm mới người dùng",
      genders: [{"id":1,"title":"Nam","e":"MALE"},{"id":2,"title":"Nữ","e":"FEMALE"}],
      member_types:[],
      fields: [
        {
          key: 'ID',
          sortable: true,
          label: 'ID'
        },
        {
          key: 'MA_NV',
          sortable: true,
          label: 'Mã nhân viên'
        },
        {
          key: 'FULL_NAME',
          sortable: true,
          label: 'Họ & tên'
        },
        {
          key: 'MOBILE',
          label: 'Số điện thoại',
          sortable: true,
        },
        {
          key: 'EMAIL',
          label: 'Email',
          sortable: true,
        },
        { key: 'actions', label: 'Actions' }
      ],
      perFields: [
        {
          key: 'ROLE_NAME',
          sortable: true,
          label: 'Chức năng'
        },
        {
          key: 'C',
          sortable: true,
          label: 'Thuộc nhóm'
        },
      ],
      required,
      email
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>